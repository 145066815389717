import { Injectable } from "@angular/core";

export interface SlugGeneratorOptions {
  allowUnderscores?: boolean;
  maxLength?: number;
  preserveCase?: boolean;
}

export interface SlugValidationOptions {
  minLength?: number;
  maxLength?: number;
  allowUnderscores?: boolean;
}

export interface SlugValidationResult {
  isValid: boolean;
  errors: string[];
}

@Injectable()
export class SlugGeneratorService {
  private readonly ACCENTED_CHARS: { [key: string]: string } = {
    á: "a",
    Á: "a",
    à: "a",
    À: "a",
    â: "a",
    Â: "a",
    ä: "a",
    Ä: "a",
    ã: "a",
    Ã: "a",
    å: "a",
    Å: "a",
    æ: "ae",
    Æ: "ae",
    ç: "c",
    Ç: "c",
    é: "e",
    É: "e",
    è: "e",
    È: "e",
    ê: "e",
    Ê: "e",
    ë: "e",
    Ë: "e",
    í: "i",
    Í: "i",
    ì: "i",
    Ì: "i",
    î: "i",
    Î: "i",
    ï: "i",
    Ï: "i",
    ñ: "n",
    Ñ: "n",
    ó: "o",
    Ó: "o",
    ò: "o",
    Ò: "o",
    ô: "o",
    Ô: "o",
    ö: "o",
    Ö: "o",
    õ: "o",
    Õ: "o",
    ø: "o",
    Ø: "o",
    œ: "oe",
    Œ: "oe",
    ß: "ss",
    ú: "u",
    Ú: "u",
    ù: "u",
    Ù: "u",
    û: "u",
    Û: "u",
    ü: "u",
    Ü: "u",
  };

  constructor() {}

  generateSlug(text: string, options: SlugGeneratorOptions = {}): string {
    if (!text) return "";

    const {
      allowUnderscores = false,
      maxLength = 100,
      preserveCase = false,
    } = options;

    // Step 1: Convert to lowercase (unless preserveCase is true) and normalize Unicode characters
    let slug = preserveCase ? text : text.toLowerCase();
    slug = slug.normalize("NFD").replace(/[\u0300-\u036f]/g, ""); // Remove diacritics

    // Step 2: Replace accented characters
    Object.entries(this.ACCENTED_CHARS).forEach(([accented, plain]) => {
      slug = slug.replace(new RegExp(accented, "g"), plain);
    });

    // Step 3: Replace spaces and underscores with dashes (if not allowed)
    const separator = allowUnderscores ? "_" : "-";
    slug = slug.replace(/[\s_]+/g, separator);

    // Step 4: Remove special characters and punctuation
    const allowedChars = allowUnderscores ? "[^a-zA-Z0-9_-]" : "[^a-zA-Z0-9-]";
    slug = slug.replace(new RegExp(allowedChars, "g"), "");

    // Step 5: Replace multiple separators with single separator
    slug = slug.replace(new RegExp(`${separator}+`, "g"), separator);

    // Step 6: Remove leading and trailing separators
    slug = slug.replace(new RegExp(`^${separator}+|${separator}+$`, "g"), "");

    // Step 7: Truncate to max length while preserving word boundaries
    if (slug.length > maxLength) {
      slug = slug
        .substring(0, maxLength)
        .replace(new RegExp(`${separator}[^${separator}]*$`, "g"), "");
    }

    return slug;
  }

  validateSlug(
    slug: string,
    options: SlugValidationOptions = {}
  ): SlugValidationResult {
    const {
      minLength = 3,
      maxLength = 100,
      allowUnderscores = false,
    } = options;

    const result: SlugValidationResult = {
      isValid: true,
      errors: [],
    };

    if (!slug) {
      result.isValid = false;
      result.errors.push("Slug cannot be empty");
      return result;
    }

    if (slug.length < minLength) {
      result.isValid = false;
      result.errors.push(`Slug must be at least ${minLength} characters long`);
    }

    if (slug.length > maxLength) {
      result.isValid = false;
      result.errors.push(`Slug cannot exceed ${maxLength} characters`);
    }

    const pattern = allowUnderscores ? /^[a-zA-Z0-9_-]+$/ : /^[a-zA-Z0-9-]+$/;
    if (!pattern.test(slug)) {
      result.isValid = false;
      result.errors.push(
        `Slug can only contain ${
          allowUnderscores
            ? "letters, numbers, dashes, and underscores"
            : "letters, numbers, and dashes"
        }`
      );
    }

    return result;
  }
}
